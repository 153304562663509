import { ProductInterestRatesSchema } from '@kvika/audur-api-types';
import { Maybe, AudurContentPageSlicesInteresttableFields } from '@kvika/audur-prismic-types';
import { Spacing } from '@kvika/audur-theme';

import InterestTableRow from './InterestTableRow';

type Props = {
  tableFields: Maybe<Array<AudurContentPageSlicesInteresttableFields>>;
  historicalInterestRates: Array<ProductInterestRatesSchema>;
};

const InterestTable = ({ tableFields, historicalInterestRates }: Props) => {
  return (
    <div style={{ overflowX: 'auto', marginTop: Spacing.Large }}>
      {tableFields?.map((field, index) => {
        const productHistoricalInterestRates = historicalInterestRates.find(
          (rates) => rates.type === field.bank_account_type_slug && rates.term === field.bank_account_term_slug
        );
        return (
          // eslint-disable-next-line react/no-array-index-key
          <InterestTableRow row={field} key={index} productHistoricalInterestRates={productHistoricalInterestRates} />
        );
      })}
    </div>
  );
};

export default InterestTable;
