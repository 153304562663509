import { UnmountClosed as CollapsibleContent } from 'react-collapse';
import { Area, ComposedChart, Line, ResponsiveContainer, Tooltip } from 'recharts';
import { Colors } from '@kvika/audur-theme';
import { toPercentageString } from '@kvika/string-utils';

import { ProductInterestRatesSchema, ProductType } from '@kvika/audur-api-types';
import styles from './InterestTableRowGraph.module.scss';

type Props = {
  isOpened: boolean;
  productHistoricalInterestRates?: ProductInterestRatesSchema;
};

const getGraphColor = (type?: ProductType): Colors => {
  switch (type) {
    case ProductType.Future: {
      return Colors.BabyBlue;
    }
    case ProductType.TermDeposit: {
      return Colors.LightOrange;
    }
    case ProductType.Savings:
    default: {
      return Colors.Green;
    }
  }
};

const InterestTableRowGraph = ({ isOpened, productHistoricalInterestRates }: Props) => {
  const rates = productHistoricalInterestRates?.interestRates;
  const normalizedRates = rates?.length === 1 ? [rates[0], rates[0]] : rates;
  const sortedRates = normalizedRates?.sort((a, b) => (a.startsAt < b.startsAt ? -1 : 1));
  const graphColor = getGraphColor(productHistoricalInterestRates?.type);

  return productHistoricalInterestRates ? (
    <CollapsibleContent isOpened={isOpened}>
      <div className={styles.chartContainer}>
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            width={500}
            height={400}
            data={sortedRates}
            margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          >
            <Tooltip
              content={({ active, payload }) => {
                if (active && payload?.length) {
                  const { rate, startsAt } = payload[0].payload;
                  if (typeof rate === 'number' && typeof startsAt === 'string') {
                    const date = new Date(startsAt);
                    const formattedDate = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
                    return (
                      <div className={styles.tooltipContainer}>
                        <p className={styles.percentage}>{toPercentageString(rate, 2)}</p>
                        <p className={styles.date}>{formattedDate}</p>
                      </div>
                    );
                  }
                }
                return null;
              }}
            />
            <Area dataKey="rate" stroke={graphColor} fill={graphColor} fillOpacity={0.2} />
            <Line dataKey="rate" stroke={graphColor} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </CollapsibleContent>
  ) : null;
};

export default InterestTableRowGraph;
