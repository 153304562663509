import { useState } from 'react';
import Image from 'next/image';
import { PrismicRichText } from '@prismicio/react';
import { AudurContentPageSlicesInteresttableFields } from '@kvika/audur-prismic-types';

import { ProductInterestRatesSchema } from '@kvika/audur-api-types';
import InterestTableRowGraph from './InterestTableRowGraph';
import styles from './InterestTableRow.module.scss';

type Props = {
  row: AudurContentPageSlicesInteresttableFields;
  productHistoricalInterestRates?: ProductInterestRatesSchema;
};

const InterestTableRow = ({ row, productHistoricalInterestRates }: Props) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <div className={styles.rowContainer}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
      <div className={styles.rowData} onClick={() => setIsOpened(!isOpened)}>
        <div className={styles.cell}>
          <PrismicRichText field={row.bank_account_type} />
        </div>
        <div className={styles.cell}>
          <PrismicRichText field={row.annual_interest_rate} />
        </div>
        <div className={styles.cell}>
          <PrismicRichText field={row.interest_payment_frequency} />
        </div>
        <div className={styles.cell}>
          <PrismicRichText field={row.indexation} />
        </div>
        <div className={styles.cell}>
          <PrismicRichText field={row.interest_on_an_annual_basis} />
        </div>
        <div className={styles.cell}>
          <PrismicRichText field={row.availability} />
          {row.bank_account_type_slug && (
            <Image
              src="/svg/arrow_down_dark.svg"
              alt="Arrow"
              width={22}
              height={8}
              className={`${styles.arrowIcon} ${isOpened ? styles.isOpened : ''}`}
              priority
            />
          )}
        </div>
      </div>
      <InterestTableRowGraph isOpened={isOpened} productHistoricalInterestRates={productHistoricalInterestRates} />
    </div>
  );
};

export default InterestTableRow;
