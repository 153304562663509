import { GetStaticProps } from 'next';
import { useState, useEffect } from 'react';
import { Colors, Spacing } from '@kvika/audur-theme';
import {
  AudurContentPage,
  AudurContentPageConnectionConnection,
  AudurContentPageEditorialContentGroup,
  AudurEditorialContent,
  AudurEditorialContentLinkGroup,
  Maybe,
  _Linkable,
  AudurLink as AudurLinkType,
  AudurContentPageSlicesInteresttable,
  AudurFooter,
  AudurHeader,
} from '@kvika/audur-prismic-types';
import { valueOrDefault } from '@kvika/audur-prismic-utils';
import { AudurPrismicClient, getNodesFromConnection } from '@kvika/audur-prismic-client';
import { useMediaQuery } from 'react-responsive';
import { ParsedUrlQuery } from 'querystring';
import { PrismicRichText, SliceComponentProps, SliceZone } from '@prismicio/react';

import { ProductInterestRatesSchema } from '@kvika/audur-api-types';
import styles from './[uid].module.scss';
import Layout from '../components/Layout';
import PrismicImageCard from '../components/prismic/PrismicImageCard';
import { MainGridContainer, GridItem } from '../components/grid-container';
import {
  ColumnClass,
  Gutters,
  EditorialContentType,
  ContentWidth,
  Alignment,
  LinkStyle,
  PageLayout,
  PreviewData,
  MetaTagInfo,
} from '../types/Types';
import { prismicConstants } from '../utils/Constants';
import { AudurButtonLink } from '../components/audur-buttons';
import { stringToInt, getMetaTags, getAudurFooter, getAudurHeader, getAudurApiClient } from '../utils/Utils';

import AudurLink from '../components/AudurLink';
import InterestTable from '../components/slices/InterestTable/InterestTable';

type Props = {
  audurContentPage: Maybe<AudurContentPage>;
  historicalInterestRates: Array<ProductInterestRatesSchema>;
  metaTags: MetaTagInfo;
  footer: AudurFooter;
  header: AudurHeader;
};

type InterestTableSliceComponentProps = SliceComponentProps<AudurContentPageSlicesInteresttable & { type: string }>;

const AudurContentsPage = ({ audurContentPage, historicalInterestRates, metaTags, footer, header }: Props) => {
  const [title, setTitle] = useState('');
  const isMobile = useMediaQuery({ query: '(max-width: 599px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 979px)' });

  useEffect(() => {
    if (audurContentPage && audurContentPage.title) {
      setTitle(audurContentPage.title);
    }
  }, [audurContentPage]);

  const getAlignedColumnClass = (contentWidth: ContentWidth, alignment: Alignment, tabletAdoptsMobileGrid = false) => {
    const isLeftAligned = alignment === Alignment.Left;
    if (tabletAdoptsMobileGrid) {
      return ColumnClass.All;
    }
    switch (contentWidth) {
      case ContentWidth._25: {
        return isLeftAligned ? ColumnClass.Left25 : ColumnClass.Right25;
      }
      case ContentWidth._50: {
        return isLeftAligned ? ColumnClass.Left50 : ColumnClass.Right50;
      }
      case ContentWidth._75: {
        return isLeftAligned ? ColumnClass.Left75 : ColumnClass.Right75;
      }
      case ContentWidth._100: {
        return ColumnClass.All;
      }
      default: {
        return ColumnClass.All;
      }
    }
  };

  const isLinkOfStyle = (maybeLink: Maybe<_Linkable>, linkStyle: LinkStyle) =>
    maybeLink && (maybeLink as AudurLinkType).linkStyle === linkStyle;

  const getButtonLinkDomFromGroup = (linkGroup: Maybe<AudurEditorialContentLinkGroup[]>) => {
    const buttonLinkDoms = linkGroup
      ?.filter(
        (g) =>
          isLinkOfStyle(g.editorialContentLink, LinkStyle.Button) ||
          isLinkOfStyle(g.editorialContentLink, LinkStyle.DarkButton)
      )
      .map((linkGroup, index) => {
        const link = linkGroup.editorialContentLink as AudurLinkType;
        return (
          <AudurButtonLink
            // eslint-disable-next-line react/no-array-index-key
            key={`button-link-group-${index}`}
            href={valueOrDefault(link.linkUrl, '/')}
            fillColor={link.linkStyle === LinkStyle.Button ? Colors.Red : Colors.DarkGray}
            openInNewTab={false}
          >
            {valueOrDefault(link.linkTitle, '/')}
          </AudurButtonLink>
        );
      });

    return buttonLinkDoms && buttonLinkDoms.length > 0 ? (
      <div className={styles.buttonLinkContainer}>{buttonLinkDoms}</div>
    ) : null;
  };

  const getLinkDomFromGroup = (linkGroup: Maybe<AudurEditorialContentLinkGroup[]>) => {
    const linkDoms = linkGroup
      ?.filter((g) => isLinkOfStyle(g.editorialContentLink, LinkStyle.Regular))
      .map((linkGroup, index) => {
        const link = linkGroup.editorialContentLink as AudurLinkType;
        return (
          // eslint-disable-next-line react/no-array-index-key
          <AudurLink key={`link-group-${index}`} href={valueOrDefault(link.linkUrl, '/')}>
            {valueOrDefault(link.linkTitle, '/')}
          </AudurLink>
        );
      });

    return linkDoms && linkDoms.length > 0 ? <div className={styles.linkContainer}>{linkDoms}</div> : null;
  };

  const renderEditorialContent = (editorialContent: AudurEditorialContent, pageLayout: PageLayout) => {
    const textContentDom = (
      <div className={styles.textContainer}>
        {editorialContent.title && <PrismicRichText field={editorialContent.title} />}
        {editorialContent.description && (
          <PrismicRichText
            field={editorialContent.description}
            components={{
              // eslint-disable-next-line react/no-unstable-nested-components
              preformatted: (children) => {
                if (children.text) {
                  // eslint-disable-next-line react/no-danger
                  return <span dangerouslySetInnerHTML={{ __html: children.text }} />;
                }
                return null;
              },
            }}
          />
        )}
      </div>
    );

    const imageDom = editorialContent.image ? (
      <div className={styles.imageContainer}>
        <PrismicImageCard editorialContent={editorialContent} />
      </div>
    ) : null;

    const linkContainerDom = getLinkDomFromGroup(editorialContent?.linkGroup);
    const buttonLinkContainerDom = getButtonLinkDomFromGroup(editorialContent?.linkGroup);
    const contentGridWidth = valueOrDefault(editorialContent.contentWidth, ContentWidth._50) as ContentWidth;
    const imageGridWidth = `${100 - stringToInt(contentGridWidth)}` as ContentWidth;
    const isHeader = (contentType: EditorialContentType) => contentType === EditorialContentType.Header;
    const hasTabletMobileGrid = pageLayout === PageLayout.AppLayout && isTabletOrMobile;

    // Content alignment states where rich text content and links should be placed
    // in the page, the image will not follow this alignment and will be
    // placed exactly opposite the content alignment
    switch (editorialContent.contentAlignment) {
      case Alignment.Left: {
        if ((isMobile || hasTabletMobileGrid) && !isHeader(editorialContent.contentType as EditorialContentType)) {
          // On mobile, the left alignment needs to be inverted so the image is rendered
          // above the content - unless its a header, then the image renders below
          return (
            <>
              {imageDom && (
                <GridItem className={`${getAlignedColumnClass(imageGridWidth, Alignment.Left, hasTabletMobileGrid)}`}>
                  {imageDom}
                </GridItem>
              )}
              <GridItem
                className={`${getAlignedColumnClass(contentGridWidth, Alignment.Right, hasTabletMobileGrid)} ${
                  styles.gridContent
                }`}
              >
                {textContentDom}
                {linkContainerDom}
                {buttonLinkContainerDom}
              </GridItem>
            </>
          );
        }
        return (
          <>
            <GridItem
              className={`${getAlignedColumnClass(contentGridWidth, Alignment.Left, hasTabletMobileGrid)} ${
                styles.gridContent
              }`}
            >
              {textContentDom}
              {linkContainerDom}
              {buttonLinkContainerDom}
            </GridItem>
            {imageDom && (
              <GridItem className={`${getAlignedColumnClass(imageGridWidth, Alignment.Right, hasTabletMobileGrid)}`}>
                {imageDom}
              </GridItem>
            )}
          </>
        );
      }
      case Alignment.Right: {
        return (
          <>
            {imageDom && (
              <GridItem className={`${getAlignedColumnClass(imageGridWidth, Alignment.Left, hasTabletMobileGrid)}`}>
                {imageDom}
              </GridItem>
            )}
            <GridItem
              className={`${getAlignedColumnClass(contentGridWidth, Alignment.Right, hasTabletMobileGrid)} ${
                styles.gridContent
              }`}
            >
              {textContentDom}
              {linkContainerDom}
              {buttonLinkContainerDom}
            </GridItem>
          </>
        );
      }
      default: {
        return null;
      }
    }
  };

  const renderEditorialItemGroup = (
    group: AudurContentPageEditorialContentGroup,
    pageLayout: PageLayout,
    index: number
  ) => {
    const editorial = group.contentPageEditorial as AudurEditorialContent;
    const pageLayoutStyle =
      pageLayout === PageLayout.Standard ? styles.standardContentContainer : styles.appLayoutContentContainer;

    switch (editorial.contentType) {
      case EditorialContentType.Header: {
        return (
          <MainGridContainer
            key={`editorial-header-${index}`}
            backgroundColor={Colors.DarkGray}
            gutters={editorial.image ? Gutters.LeftOnly : Gutters.Both}
            className={`${pageLayoutStyle} ${styles.headerContainer}`}
          >
            {renderEditorialContent(editorial, pageLayout)}
          </MainGridContainer>
        );
      }
      case EditorialContentType.Inline: {
        return (
          <MainGridContainer
            key={`editorial-inline-item-${index}`}
            backgroundColor={Colors.Beige}
            gutters={Gutters.Both}
            className={`${pageLayoutStyle} ${styles.inlineContentContainer}`}
          >
            {renderEditorialContent(editorial, pageLayout)}
          </MainGridContainer>
        );
      }
      case EditorialContentType.PageSection: {
        return (
          <MainGridContainer
            key={`editorial-page-section-${index}`}
            backgroundColor={Colors.LightOrange}
            gutters={Gutters.Both}
            className={`${pageLayoutStyle} ${styles.pageSectionContainer}`}
          >
            {renderEditorialContent(editorial, pageLayout)}
          </MainGridContainer>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Layout title={title} metaTags={metaTags} footer={footer} header={header}>
      <>
        {audurContentPage?.editorialContentGroup?.map((group, index) => {
          return renderEditorialItemGroup(
            group,
            valueOrDefault(audurContentPage.pageLayout, PageLayout.Standard) as PageLayout,
            index
          );
        })}
        {audurContentPage?.slices && (
          <MainGridContainer
            key="content-page-slize-zone"
            backgroundColor={Colors.Silver}
            gutters={Gutters.Both}
            className={styles.standardContentContainer}
          >
            <GridItem className={ColumnClass.All} style={{ marginBottom: Spacing.Large }}>
              <SliceZone
                slices={audurContentPage.slices.map((slice) => ({ ...slice, type: valueOrDefault(slice.type, '') }))}
                components={{
                  interesttable: ({ slice }: InterestTableSliceComponentProps) => {
                    return (
                      <InterestTable tableFields={slice.fields} historicalInterestRates={historicalInterestRates} />
                    );
                  },
                }}
              />
            </GridItem>
          </MainGridContainer>
        )}
      </>
    </Layout>
  );
};

interface Params extends ParsedUrlQuery {
  uid: string;
}

export default AudurContentsPage;

export const getStaticProps: GetStaticProps<Props, Params> = async ({ params, previewData, locale }) => {
  const uId = params ? params.uid : '';
  const audurClient = new AudurPrismicClient(prismicConstants.apiToken);
  const audurContentPage = await audurClient.getAudurContentPageByUid({
    previewData: previewData as PreviewData,
    variables: { uId, lang: locale },
  });

  const footer = await getAudurFooter();
  const header = await getAudurHeader();

  const metaTags = getMetaTags({
    ...audurContentPage,
    __typename: 'AudurContentPage',
  } as Maybe<AudurContentPage>);

  const apiClient = getAudurApiClient();

  return {
    props: {
      audurContentPage,
      historicalInterestRates: uId === 'vaxtatafla' ? await apiClient.getHistoricalInterestRates() : [],
      metaTags,
      footer,
      header,
    },
  };
};

export async function getStaticPaths() {
  const audurClient = new AudurPrismicClient(prismicConstants.apiToken);
  const maybeContentPages: Maybe<AudurContentPageConnectionConnection> = await audurClient.getAllAudurContentPages();
  const contentPages: Array<AudurContentPage> = getNodesFromConnection(maybeContentPages);

  const paths =
    contentPages.map((contentPage) => {
      return {
        params: {
          uid: `${contentPage._meta.uid}`,
        },
        locale: `${contentPage._meta.lang}`,
      };
    }) || [];

  return {
    paths,
    fallback: false,
  };
}
