import React, { CSSProperties } from 'react';
import Image from 'next/image';
import { AudurEditorialContent, Maybe } from '@kvika/audur-prismic-types';
import { valueOrDefault } from '@kvika/audur-prismic-utils';
import { useMediaQuery } from 'react-responsive';
import styles from './PrismicImageCard.module.scss';
import { EditorialContentType } from '../../types/Types';

type Props = {
  editorialContent: AudurEditorialContent;
  style?: CSSProperties;
};

const PrismicImageCard = ({ editorialContent, style }: Props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 979px)' });
  const imageOffset = (type: EditorialContentType) => {
    switch (type) {
      case EditorialContentType.Header: {
        return isTabletOrMobile ? -50 : -100;
      }
      default: {
        return 0;
      }
    }
  };

  const getImageHeight = (imageHeight: Maybe<number>): number | undefined => {
    return imageHeight || undefined;
  };

  return editorialContent.image ? (
    <div
      className={styles.imageContainer}
      style={{
        minHeight: getImageHeight(editorialContent.imageHeight),
        marginBottom: imageOffset(
          valueOrDefault(editorialContent.contentType as EditorialContentType, EditorialContentType.Inline)
        ),
        ...style,
      }}
    >
      <Image
        className={styles.image}
        src={editorialContent.image.url}
        alt={editorialContent.image.alt}
        fill
        sizes="100%"
        priority
        objectFit="contain"
      />
    </div>
  ) : null;
};

export default PrismicImageCard;
